import {Link} from "../atoms/link";
import {DrupalNode, DrupalTaxonomyTerm} from "next-drupal";
import {MediaImage} from "../templates/media/media--image";
import Image from "next/image";

import Slider from "react-slick";
import {MouseEventHandler, PropsWithChildren, ReactNode, useState} from "react";
import {FrostedGlassContainer} from "../atoms/gradient-container";
import {PlaceHolderHeroImage} from "./hero";
import classNames from "classnames";
import {DrupalVideoMedia, IndustrySegmentsTaxonomyTerm} from "../../types/hygiena-types";
import {Flag} from "../atoms/flag";
import {
  ARTICLE_TEASER_IMAGE_STYLE,
  PRODUCT_LARGE_IMAGE_STYLE,
  PRODUCT_TEASER_IMAGE_STYLE
} from "../../types/image-styles";
import {absoluteURL} from "../../lib/absolute-url";
import {WidgetFrostedDetailCard} from "./widget--frosted-detail-card";
import {MediaVideoTeaser} from "../templates/media/media--video";

interface RelatedNewsProps {
  articles: DrupalNode[],
}

interface RelatedProductProps {
  products: DrupalNode[],
}

interface TrainingVideosProps {
  videos?: DrupalVideoMedia[],
  openModal?: Function|null,
  openModalContent?: Function|null,
  usePath?: boolean,
}

interface ArrowProps {
  onClick?: MouseEventHandler,
}

/**
 * The previous arrow for the article slider.
 *
 * @param props
 * @constructor
 */
function PrevArrow(props: ArrowProps): JSX.Element {
  const { onClick } = props;
  return (
    <div className={classNames("absolute flex top-0 bottom-0 -left-6 lg:-left-12 xl:-left-12 2xl:-left-16 z-10 items-center",  {"hidden": onClick === null})}>
      <Image src={`${absoluteURL("/sites/default/files/icons/black/chevron-left.webp")}`} alt={"Go to previous"} width={45} height={65} className="cursor-pointer opacity-10 hover:opacity-30 bg-transparent h-auto p-2 lg:p-0" onClick={onClick} />
    </div>
  );
}

/**
 * The next arrow for the article slider.
 *
 * @param props
 * @constructor
 */
function NextArrow(props: ArrowProps): JSX.Element {
  const { onClick } = props;

  return (
    <div className={classNames("absolute flex top-0 bottom-0 -right-6 lg:-right-12 xl:-right-12 2xl:-right-16 z-10 items-center", {"hidden": onClick === null})}>
      <Image src={`${absoluteURL("/sites/default/files/icons/black/chevron-right.webp")}`} alt={"Go to next"} width={45} height={65} className="cursor-pointer opacity-10 hover:opacity-30 bg-transparent h-auto p-2 lg:p-0" onClick={onClick}/>
    </div>
  );
}

/**
 * An image for the article slider.
 *
 * @param node
 *   The node.
 * @param props
 *   Additional props.
 * @constructor
 */
function ArticleSliderImage({node, ...props}: {node: DrupalNode}&PropsWithChildren) {
  if (node?.field_article_image) {
    return (
      <MediaImage
        media={node.field_article_image}
        loading={"lazy"}
        sizes="405px"
        imageStyle={ARTICLE_TEASER_IMAGE_STYLE}
        style={{position: "absolute", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center top"}}
        childClass="transition-all duration-1000"
        wrapperClass="relative h-full w-full"
      >
        {props.children}
      </MediaImage>
    )
  }
  return (
    <div>
      <PlaceHolderHeroImage/>
      {props.children}
    </div>
  );
}

/**
 * Renders a slider of related news.
 *
 * @todo merge this and the Product Slider into something generic.
 *
 * @constructor
 */
export function ArticleSlider ({articles}: RelatedNewsProps) {
  const slickSettings = {
    centerMode: false,
    centerPadding: "30px",
    arrows: true,
    nav: true,
    dots: false,
    lazyLoad: "ondemand",
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  articles = articles.filter(article => article?.id !== "unknown" && article?.field_article_image?.type !== "unknown" && article?.status && (article?.field_article_image?.image || article?.field_article_image?.bf_image));

  return (
    <div className="relative max-md:px-4">
      <Slider {...slickSettings}>
        {articles.map(node => (
          <Link href={node?.path?.alias ?? ""} key={`article-slider--${node.id}`} className="bg-white article-card overflow-hidden box-shadow-md hover:box-shadow-lg transition-all duration-500 rounded-md opacity-1 min-w-96 h-84">
            <div className="h-84">
              <ArticleSliderImage node={node}>
                <FrostedGlassContainer className="absolute bottom-0 pt-5 pb-4 w-full min-h-44 rounded-none border-none">
                  {node?.field_article_type?.name && (
                    <div className="px-6 pb-1 text-primary font-bold z-10">
                      {node?.field_article_type?.name}
                    </div>
                  )}
                  <div className="px-6 text-black text-xl tracking-tight font-light z-10 line-clamp-3">
                    {node.title}
                  </div>
                </FrostedGlassContainer>
              </ArticleSliderImage>
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  )
}

/**
 * Renders a slider of related products.
 *
 * @constructor
 */
export function ProductSlider ({products}: RelatedProductProps) {
  const slickSettings = {
    centerMode: false,
    centerPadding: "30px",
    arrows: true,
    nav: true,
    dots: false,
    infinite: false,
    lazyLoad: "ondemand",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  products = products?.length ? products.filter(item => item?.status && item?.type !== "unknown") : [];

  return (
    <div className="relative max-md:px-4">
      <Slider {...slickSettings}>
        {products.map((node, key) => (
          <div key={`product-slider--${node.id}--${key}`} className={"bg-white transition-all duration-500 rounded-md box-shadow-md hover:box-shadow-lg"}>
            {node?.path?.alias && node?.field_product_image && (
              <Link href={node.path.alias ?? ""} className="opacity-1 w-96 h-84">
                <div className="h-84 relative">
                  <MediaImage
                    loading={"lazy"}
                    media={node.field_product_image[0]}
                    imageStyle={PRODUCT_TEASER_IMAGE_STYLE}
                    style={{maxHeight: "75%", objectFit: "contain"}}
                    sizes="405px"
                    className="relative h-full flex p-4 scale-image-hover"
                  />
                  <div className="absolute bottom-0 bg-hygienaLight/80 pt-5 pb-4 w-full min-h-[120px] backdrop-blur-sm rounded-b-md">
                    <div className="px-6 pb-1 text-primary font-bold">
                      {node?.field_product_category?.parent?.[0]?.name && node.field_product_category.parent[0].name}
                      {!node?.field_product_category?.parent?.[0]?.name && node?.field_product_class?.name && node.field_product_class.name}
                    </div>
                    <div className="px-6 text-black text-xl tracking-tight font-light">
                      {node?.field_product_brand?.name?.length ? (
                        <>
                          {`${node.field_product_brand.name}${node.field_product_brand?.field_brand_suffix ?? ""} ${node.title}`}
                        </>
                      ) : <>{node.title}</>}
                    </div>
                  </div>
                </div>
              </Link>
            )}
          </div>
        ))}
      </Slider>
    </div>
  )
}

/**
 * Renders a slider of training videos.
 *
 * @constructor
 */
export function TrainingVideosSlider ({videos, openModal, openModalContent, usePath} : TrainingVideosProps) {
  if (!videos?.length) return <></>
  const slickSettings = {
    centerMode: false,
    centerPadding: "30px",
    arrows: true,
    nav: true,
    dots: false,
    infinite: false,
    lazyLoad: "ondemand",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="relative max-md:px-4">
      <Slider {...slickSettings}>
        {videos.filter(video => video?.field_media_oembed_video?.length).map((video, key) => (
          <div key={`training-videos-slider--${video.id}--${key}`}>
            {(!usePath || !video?.path?.alias) && (
              <MediaVideoTeaser video={video} openModal={openModal} openModalContent={openModalContent}/>
            )}
            {(usePath && video?.path?.alias) && (
              <Link href={video.path.alias} title={video.name} target="_blank">
                <MediaVideoTeaser video={video} />
              </Link>
            )}
          </div>
        ))}
      </Slider>
    </div>
  )
}

/**
 * A segment term slide for the Industry Segment slider.
 *
 * @param term
 *   The Industry Segment term.
 * @param callback
 *   The callback to run on clicking a slide.
 * @constructor
 */
function IndustrySegmentSliderSlide ({term, callback, active}: {term: IndustrySegmentsTaxonomyTerm, callback: Function, active?: boolean}) {
  const [hover, setHover] = useState<boolean>();
  return (
    <div
         onClick={() => callback(term?.id)}
         onMouseEnter={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
         className={`bg-white box-shadow-md hover:box-shadow-lg transition-all duration-500 rounded-md cursor-pointer industry-segment-slide-${term?.drupal_internal__tid}`}>
      <div className="h-84 relative overflow-hidden rounded-md">
        {term?.field_industry_hero_image && (
          <>
            <MediaImage
              loading={"lazy"}
              media={term.field_industry_hero_image}
              imageStyle={ARTICLE_TEASER_IMAGE_STYLE}
              className="absolute w-full h-full hover:scale-105 transition-all duration-1000"
              sizes="405px"
              style={{position: "absolute", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center"}}
              childClass="transition-all duration-1000"
            />
            <FrostedGlassContainer className={classNames(
              "absolute bottom-0 w-full min-h-[110px] rounded-none border-none flex flex-col justify-center transition-all duration-500",
              {"bg-primary !from-primary !to-primary": hover || active}
            )}>
              {term?.parent?.[0]?.id !== "virtual" && (
                <div className={classNames("px-6 pb-1 text-primary font-bold z-10", {"text-white": hover || active})}>
                  {term?.parent?.[0]?.name}
                </div>
              )}
              <div className={classNames("px-6 text-black text-xl tracking-tight font-light z-10 line-clamp-3", {"text-white": hover || active})}>
                <span className="text-3xl">{term.name}</span>
              </div>
            </FrostedGlassContainer>
          </>
        )}
      </div>
    </div>
  )
}

/**
 * Renders a slider of industry/segments.
 *
 * @constructor
 */
export function IndustrySegmentSlider ({terms, callback, activeSegment, steps}: {
  terms: IndustrySegmentsTaxonomyTerm[],
  steps: {[key: string]: DrupalTaxonomyTerm[]}[],
  callback: Function,
  activeSegment?: IndustrySegmentsTaxonomyTerm
}) {
  const slickSettings = {
    centerMode: false,
    centerPadding: "30px",
    arrows: true,
    nav: true,
    dots: false,
    infinite: false,
    lazyLoad: "ondemand",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="relative max-md:px-4">
      <Slider {...slickSettings}>
        {terms.map(term => (
          <IndustrySegmentSliderSlide key={`industry-segment-slider-slide--${term.id}`} term={term} callback={callback} active={activeSegment?.id === term?.id}/>
        ))}
      </Slider>
    </div>
  )
}

/**
 * An image for the affiliate slider.
 *
 * @param node
 *   The node.
 * @param props
 *   Additional props.
 * @constructor
 */
function AffiliateSliderImage({node, ...props}: {node: DrupalNode, children?: ReactNode|ReactNode[]}) {
  if (node?.field_place_image) {
    return (
      <MediaImage
        loading={"lazy"}
        media={node.field_place_image}
        sizes="405px"
        imageStyle={PRODUCT_LARGE_IMAGE_STYLE}
        style={{width: "100%", height: "100%", objectFit: "cover", objectPosition: "center top"}}
        className="overflow-hidden max-w-full max-h-full w-full h-full"
        childClass="hover:scale-105"
      >
        {props.children}
      </MediaImage>
    )
  }
  return (
    <div>
      <PlaceHolderHeroImage/>
      {props.children}
    </div>
  );
}

/**
 * Renders a slider of Places.
 *
 * @todo merge this and the Article Slider into something generic.
 *
 * @constructor
 */
export function AffiliateSlider ({nodes}: {nodes: DrupalNode[]}) {
  const slickSettings = {
    centerMode: false,
    centerPadding: "30px",
    arrows: true,
    nav: true,
    dots: false,
    infinite: false,
    lazyLoad: "ondemand",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="paragraph--affiliate-slider relative max-md:px-4">
      <Slider {...slickSettings}>
        {nodes.map(node => (
          <div key={node.id} className={"bg-white box-shadow-md hover:box-shadow-lg transition-all duration-500 rounded-md"}>
            {node?.field_place_related_page?.path?.alias && node?.field_place_image && (
              <Link href={node.field_place_related_page.path.alias ?? ""} className="opacity-1 w-96 h-84">
                <div className="h-84 overflow-hidden rounded-md relative">
                  <AffiliateSliderImage node={node}>
                    <div className="absolute bottom-0 bg-hygienaLight/80 pt-5 pb-4 w-full min-h-[120px] rounded-b-md backdrop-blur-sm">
                      <div className="px-6 pb-1 font-bold text-black">
                        {node?.field_place_address?.country_code && (
                          <Flag type={node.field_place_address.country_code} className={"inline mr-3"}/>
                        )}
                        {node?.field_place_address?.region_name && node.field_place_address.region_name}
                      </div>
                      <div className="px-6 text-black text-3xl tracking-tight font-light">
                        {node.title}
                      </div>
                    </div>
                  </AffiliateSliderImage>
                </div>
              </Link>
            )}
          </div>
        ))}
      </Slider>
    </div>
  )
}

/**
 * Renders a slider of Solutions.
 *
 * @constructor
 */
export function FeaturedSolutionsSlider ({nodes}: {nodes: DrupalNode[]}) {
  const slickSettings = {
    centerMode: false,
    centerPadding: "30px",
    arrows: true,
    nav: true,
    dots: false,
    infinite: false,
    lazyLoad: "ondemand",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="paragraph--featured-solutions-slider relative max-md:px-4">
      <Slider {...slickSettings}>
        {nodes.map(node => (
          <div key={node.id} className={"bg-white box-shadow-md hover:box-shadow-lg transition-all duration-500 rounded-md h-full"}>
            {node?.field_solution_image && (
              <WidgetFrostedDetailCard title={node.title} body={node?.body?.processed} image={node?.field_solution_image}/>
            )}
          </div>
        ))}
      </Slider>
    </div>
  )
}

